<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/importacao"
                @onBeforeSave="onBeforeSave"
                @onValidate="onValidate"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
            >
                <template #content>
                    <div class="card p-fluid w-full">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="importacao-form" />
                            <h3 class="ml-1">{{ title }}</h3>
                        </div>
                        <div v-if="!loading" class="grid">
                            <div class="field col-6" v-if="form.id">
                                <label for="id">Código</label>
                                <InputText :disabled="!!form.id" id="id" v-model.trim="form.id" required="true" autofocus autocomplete="off" />
                            </div>
                            <div class="field col-6">
                                <label for="companyId">Empresa</label>
                                <Dropdown
                                    :disabled="!!form.id"
                                    id="companyId"
                                    v-model="form.companyId"
                                    :options="companies"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Escolha"
                                >
                                </Dropdown>
                            </div>

                            <div class="field col-6" v-if="form.origem">
                                <label for="origem">Origem</label>
                                <InputText
                                    :disabled="!!form.id"
                                    id="origem"
                                    v-model.trim="form.origem"
                                    required="true"
                                    autofocus
                                    autocomplete="off"
                                    :class="{ 'p-invalid': submitted && !form.origem }"
                                />
                                <small class="p-error" v-if="submitted && !form.origem">Origem é obrigatório.</small>
                            </div>

                            <div class="field col-6" v-if="!form.id">
                                <label for="origens">Origens</label>
                                <MultiSelect
                                    id="origens"
                                    v-model="origensSelecionadas"
                                    :options="origens"
                                    :filter="true"
                                    :optionDisabled="checkDisabledOptions"
                                    display="chip"
                                    optionLabel="name"
                                />
                            </div>

                            <div class="field col-6">
                                <label for="idCliente">Cliente (opcional)</label>
                                <Dropdown
                                    :filter="true"
                                    :disabled="!!form.id"
                                    id="idCliente"
                                    v-model="form.idCliente"
                                    :showClear="false"
                                    :options="customers"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="Cliente (opcional)"
                                ></Dropdown>
                            </div>

                            <div class="field col-6">
                                <label for="idPrestador">Prestador (opcional)</label>
                                <DropdownPrestador
                                    :disabled="!!form.id"
                                    v-model="form.prestador"
                                    :showClear="false"
                                    :idValue="form.idPrestador"
                                    placeholder="Prestador (opcional)"
                                    optionValue="id"
                                />
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>

            <TabView v-if="form.id">
                <TabPanel :header="'Erros (' + items.erros.length + ')'">
                    <GridItens :records="items.erros"></GridItens>
                </TabPanel>
                <TabPanel :header="'Sucessos (' + items.sucessos.length + ')'">
                    <GridItens :records="items.sucessos"></GridItens>
                </TabPanel>
                <TabPanel :header="'Criadas (' + items.criadas.length + ')'">
                    <GridItens :records="items.criadas"></GridItens>
                </TabPanel>
                <TabPanel :header="'Iniciadas (' + items.iniciadas.length + ')'">
                    <GridItens :records="items.iniciadas"></GridItens>
                </TabPanel>
            </TabView>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getClientBase } from '../../services/http';
import GridItens from './components/GridItens.vue';
import DropdownPrestador from '../prestadores/components/DropdownPrestador.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';
import * as storage from '../../common/storage';

export default {
    data() {
        return {
            profile: {},
            loading: false,
            service: null,
            form: {},
            submitted: false,
            items: {
                criadas: [],
                iniciadas: [],
                sucessos: [],
                erros: []
            },
            customers: [],
            companies: [],
            origensSelecionadas: [],
            origens: [
                {
                    id: 'UNIDADE',
                    name: 'UNIDADE'
                },
                {
                    id: 'CLIENTE',
                    name: 'CLIENTE'
                },
                {
                    id: 'SETOR',
                    name: 'SETOR'
                },
                {
                    id: 'CARGO',
                    name: 'CARGO'
                },
                {
                    id: 'HIERARQUIA',
                    name: 'HIERARQUIA'
                },
                {
                    id: 'FUNCIONARIO',
                    name: 'FUNCIONARIO'
                },
                {
                    id: 'PRESTADOR',
                    name: 'PRESTADOR'
                },
                {
                    id: 'PRESTADOR_CLIENTE',
                    name: 'PRESTADOR_CLIENTE'
                },
                {
                    id: 'PRESTADOR_PROCEDIMENTO',
                    name: 'PRESTADOR_PROCEDIMENTO'
                },
                {
                    id: 'PRESTADOR_VALOR_PADRAO',
                    name: 'PRESTADOR_VALOR_PADRAO'
                },
                {
                    id: 'PRESTADOR_VALOR_CLIENTE',
                    name: 'PRESTADOR_VALOR_CLIENTE'
                },
                {
                    id: 'MEDICOS',
                    name: 'MEDICOS'
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/importacao');
    },
    mounted() {
        if (this.$route.params.id) {
            this.findItensGroupByStatus(this.$route.params.id);
        }
        this.loadCustomers();
        this.loadCompanies();
        this.profile = storage.getProfile();
    },
    watch: {
        'form.prestador'() {
            this.form.idPrestador = this.form.prestador?.id;
        }
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Visualizar importação' : 'Adicionar importação';
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.form = data;

            const parametrosExtras = this.form.parametrosExtras ? JSON.parse(this.form.parametrosExtras) : {};
            if (parametrosExtras.idPrestador) {
                this.form.idPrestador = +parametrosExtras.idPrestador;
            }
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Preencha todos os campos obrigatórios.',
                    life: 3000
                });
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            return !this.form.companyId || !this.form.origens.length;
        },
        checkDisabledOptions(option) {
            const data = new Date();
            const horaAtual = data.getHours();
            if (horaAtual > 7 && horaAtual < 18) {
                if (
                    !this.form.idCliente &&
                    (option.id === 'SETOR' || option.id === 'FUNCIONARIO' || option.id === 'CARGO' || option.id === 'HIERARQUIA')
                ) {
                    return true;
                }
                if (
                    !this.form.idPrestador &&
                    (option.id === 'PRESTADOR_CLIENTE' ||
                        option.id === 'PRESTADOR_PROCEDIMENTO' ||
                        option.id === 'PRESTADOR_VALOR_PADRAO' ||
                        option.id === 'PRESTADOR_VALOR_CLIENTE')
                ) {
                    return true;
                }
            }
            return false;
        },
        async onBeforeSave() {
            this.submitted = true;
            this.form.userIdCreate = this.profile.id;
            this.form.origens = this.origensSelecionadas.map((p) => p.id);
            this.form.idPrestador = this.form?.prestador?.id;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async findItensGroupByStatus(id) {
            const response = await getClientBase().get('/importacao/find-itens-group-by-status/' + id);
            this.items.criadas = response.data.criadas;
            this.items.iniciadas = response.data.iniciadas;
            this.items.sucessos = response.data.sucessos;
            this.items.erros = response.data.erros;
        },
        async loadCustomers() {
            const { data } = await getClientBase().get('/customers/combo');
            this.customers = data;
        },
        async loadCompanies() {
            const { data } = await getClientBase().get('/importacao/companies/all');
            this.companies = data;
        }
    },
    components: { GridItens, DropdownPrestador, AppInfoManual }
};
</script>
